import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { getNoRestrict, postData } from "../../services/Services";
import styles from "./Login.module.css";
import { css } from "@emotion/react";
import { addToken, validateToken } from "../../redux/action/validateToken";
import Button from "../atomic/Button/Button";
import InputText from "../atomic/Input/InputText";
import {
  Dialog,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box } from "@mui/system";
import LoginSuccess from "./LoginSuccess";
import ForgetPassword from "../ForgetPassword/ForgetPassword";
import ForgetPasswordOTP from "../ForgetPassword/ForgetPasswordOTP";
import ForgetPasswordSuccess from "../ForgetPassword/ForgetPasswordSuccess";
import ForgetPasswordForm from "../ForgetPassword/ForgetPasswordForm";
import CustomNotification from "../atomic/Alert/CustomNotification";
import RegisterSuccess from "../Register/RegisterSuccess";
import OTPRegisterMobile from "../OTP/OTPRegisterMobile";
import OTPRegister from "../OTP/OTPRegister";
import CustomSuccessNotification from "../atomic/Alert/CustomSuccessNotification";
import { saveUser } from "../../redux/action/userAction";
import NewRegister from "../NewRegister/NewRegister";
import CustomModal from "../atomic/Modal/CustomModal";

export default function Login() {
  // React Router Data
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const resource_name = searchParams.get("resource_name");
  const resource_id = searchParams.get("resource_id");
  const redirect_uri = searchParams.get("redirect_uri");
  const request_id = searchParams.get("request_id");
  const activation = searchParams.get("activation");
  const userID = searchParams.get("userID");
  const phone = searchParams.get("phone");
  const activationURL = searchParams.get("activationURL");
  const successURL = searchParams.get("success");
  const isRegister = searchParams.get("isRegister");
  const redirectOtp = searchParams.get("redirect_otp");
  const twofa_type = searchParams.get("2fa_type");

  // Local State
  const [loading, setLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [notValidate, setNotValidate] = useState(true);

  // Local State Login
  const [loginData, setLoginData] = useState({
    userName: "",
    password: "",
    requestID: request_id,
    redirectURI: redirect_uri,
    resourceID: resource_id,
  });
  const [typePassword, setTypePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorLogin, setErrorLogin] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [loginErrorModal, setLoginErrorModal] = useState(false);
  const [openLoginSuccess, setOpenLoginSuccess] = useState(false);
  const [otpSuccess] = useState(true);

  // Local State Register
  const [openRegister, setOpenRegister] = useState(false);
  const [successRegister, setSuccessRegister] = useState(false);

  // Local State Forget Password
  const [openForgot, setOpenForgot] = useState(false);
  const [openForgotSuccess, setOpenForgotSuccess] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [openForgotForm, setOpenForgotForm] = useState(false);
  const [forgotOtpError, setForgotOtpError] = useState(false);

  // Local State Activation
  const [openActivation, setOpenActivation] = useState(false);

  // Redux State
  const dispatch = useDispatch();
  const isValidated = useSelector(
    (state) => state.validateData.data.isValidated
  );

  // Material State
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickShowPassword = () => {
    if (typePassword) {
      setTypePassword(false);
    } else {
      setTypePassword(true);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseLoginSuccess = () => {
    setOpenLoginSuccess(false);
  };

  useEffect(() => {
    if (!isValidated) {
      dispatch(addToken(token));
      getNoRestrict("/v3/auth/token/internal/verify", token)
        .then((res) => {
          if (res.data.status === 1) {
            dispatch(validateToken({ isValidated: true }));
            if (activation) {
              setOpenActivation(true);
            } else {
              setOpenActivation(false);
            }
            setNotValidate(true);
          } else {
            dispatch(validateToken({ isValidated: false }));
            setNotValidate(false);
          }
        })
        .catch((err) => {
          if (err) {
            setNotValidate(false);
          }
        });
    } else {
      setNotValidate(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [isValidated, dispatch, token]);

  // Handle Change
  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginLoading(true);
    const finalData = {
      ...loginData,
      "2fa_type": twofa_type ? twofa_type : "",
      requestID: request_id,
      redirectURI: redirect_uri,
      resourceID: resource_id,
    };
    postData("/v3/auth/login/pwa/verification", token, finalData)
      .then((res) => {
        setLoginLoading(false);
        setErrorTitle("");
        if (res.data.status === 1) {
          if (res.data.data.resourceActivation) {
            onRedirectURI(res.data.data.authorization_code, res.data.data.is2FAType, res.data.data.clientID);
          } else {
            dispatch(saveUser(res.data.data.userModel));
            setOpenLoginSuccess(true);
            setErrorMessage({});
            setErrorLogin("");
            setLoginData({ userName: "", password: "" });
          }
        } else if (res.data.status === 1000037) {
          setErrorMessage("Username atau kata sandi salah, silahkan coba lagi");
          setErrorLogin("");
          setLoginErrorModal(false);
        } else if (res.data.status === 300020) {
          setLoginErrorModal(true);
          if (res.data.data.nexsoft.payload.status.code === "E-4-AUT-SRV-003") {
            if (twofa_type === null) {
              setErrorLogin("Terjadi kesalahan mohon kontak developer Anda.");
              setErrorTitle("System Error!");
            } else {
              setErrorLogin(res.data.data.nexsoft.payload.status.message);
            }
          } else {
            setErrorLogin("Username atau kata sandi salah, silahkan coba lagi");
            setErrorTitle("Tidak Berhasil!");
          }
          setErrorMessage("");
          setOpenLoginSuccess(false);
        } else {
          setErrorLogin("Gagal melakukan login");
          setErrorMessage("");
          setErrorTitle("Tidak Berhasil!");
          setOpenLoginSuccess(false);
          setLoginErrorModal(true);
        }
      })
      .catch((err) => {
        setLoginLoading(false);
        if (err) {
          setErrorLogin("Terjadi kesalahan mohon kontak developer Anda.");
          setErrorTitle("System Error!");
          setLoginErrorModal(true);
          setErrorMessage("");
          setOpenLoginSuccess(false);
        }
      });
  };

  const handleCloseActivation = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    setOpenActivation(false);
  };

  const addResource = () => {
    // Add Resource Function
    let addResourceData = {
      requestID: request_id,
      resourceID: resource_id,
    };
    postData("/v3/auth/login/pwa/add/resource", token, addResourceData)
      .then((res) => {
        if (res.data.status === 1) {
          setOpenLoginSuccess(false);
          onRedirectURI(res.data.data.authorization_code, false, "");
        } else if (res.data.status === 300047) {
          setErrorLogin(res.data.note);
          setErrorMessage("");
          setLoginErrorModal(true);
        } else {
          setErrorLogin(res.data.note.nexsoft.payload.status.message);
          setErrorMessage("");
          setLoginErrorModal(true);
        }
      })
      .catch((err) => {
        if (err) {
          setOpenLoginSuccess(false);
          setErrorLogin("Add resource gagal");
          setErrorMessage("");
          setLoginErrorModal(true);
        }
      });
  };

  const onRedirectURI = (code, twofa_type, client_id) => {
    window.location.replace(
      `${redirect_uri}?&auth_code=${code}&request_id=${request_id}&is2FAType=${twofa_type}&clientID=${client_id}`
    );
  };

  useEffect(() => {
    if (redirectOtp) {
      setTimeout(() => {
        window.location.replace(successURL);
      }, 5000);
    }
  }, [redirectOtp]);

  useEffect(() => {
    if (!navigator.onLine) {
      setErrorLogin("Jaringan terputus. Silakan ulangi lagi");
      setErrorTitle("Tidak Berhasil!");
      setLoginErrorModal(true);
    }
  }, [navigator.onLine]);

  return (
    <div className={styles.loginContainer}>
      {matches ? (
        <Dialog
          className={styles.activationContainerMobile}
          open={openActivation}
          onClose={handleCloseActivation}
        >
          <Box className={styles.mobileLoginSuccess}>
            <OTPRegisterMobile
              onClose={handleCloseActivation}
              userID={userID}
              activation={activation}
              activationURL={activationURL}
              phone={phone}
              loading={() => {}}
            />
          </Box>
        </Dialog>
      ) : (
        <Dialog
          style={{ borderRadius: "20px" }}
          open={openActivation}
          onClose={() => setOpenActivation(false)}
        >
          <Box className={styles.loginSuccess}>
            <OTPRegister
              onClose={() => setOpenActivation(false)}
              userID={userID}
              activation={activation}
              activationURL={activationURL}
              phone={phone}
              loading={() => {}}
            />
          </Box>
        </Dialog>
      )}

      <CustomModal
        isCustom={true}
        open={openLoginSuccess}
        onClose={() => handleCloseLoginSuccess()}
      >
        <LoginSuccess
          onClose={() => setOpenLoginSuccess(false)}
          successURL={successURL}
          resource={resource_name ? resource_name : "Grochat"}
          onSubmit={() => addResource()}
        />
      </CustomModal>

      <CustomModal
        isCustom={true}
        open={openForgot}
        onClose={() => {
          setOpenForgot(false);
          setForgotOtpError(false);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "350px",
            margin: "auto",
          }}
        >
          <ForgetPassword
            onClose={() => setOpenForgot(false)}
            onOpenOtp={() => setOpenOtp(true)}
          />
        </Box>
      </CustomModal>

      <CustomModal
        isCustom={true}
        open={openOtp}
        onClose={() => setOpenOtp(false)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "350px",
            margin: "auto",
          }}
        >
          <ForgetPasswordOTP
            onClose={() => setOpenOtp(false)}
            onSuccess={() => setOpenForgotForm(true)}
            onError={forgotOtpError}
          />
        </Box>
      </CustomModal>

      <CustomModal
        isCustom={true}
        open={openForgotForm}
        onClose={() => setOpenForgotForm(false)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "350px",
            margin: "auto",
          }}
        >
          <ForgetPasswordForm
            onClose={() => setOpenForgotForm(false)}
            onSuccess={() => setOpenForgotSuccess(true)}
            onOpenOtp={() => {
              setOpenOtp(true);
              setForgotOtpError(true);
            }}
          />
        </Box>
      </CustomModal>

      <CustomModal
        isCustom={true}
        open={openForgotSuccess}
        onClose={() => setOpenForgotSuccess(false)}
      >
        <ForgetPasswordSuccess />
      </CustomModal>

      <CustomModal
        isCustom={true}
        open={openRegister}
        onClose={() => setOpenRegister(false)}
      >
        <NewRegister
          onClose={() => setOpenRegister(false)}
          onSuccess={() => setSuccessRegister(true)}
        />
      </CustomModal>

      <CustomModal
        isCustom={true}
        open={successRegister}
        onClose={() => setSuccessRegister(false)}
      >
        <RegisterSuccess onClose={() => setSuccessRegister(false)} />
      </CustomModal>

      {loginLoading && (
        <Box
          position={"absolute"}
          width="100vw"
          top="0"
          left="0"
          height="100vh"
          zIndex="9999999999"
          display="flex"
          alignItems="center"
          justifyContent={"center"}
        >
          <Box
            position={"absolute"}
            width="100vw"
            top="0"
            left="0"
            sx={{ background: "black", opacity: "10%" }}
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent={"center"}
          ></Box>
          <PuffLoader
            css={css}
            color={"black"}
            loading={loginLoading}
            size={150}
          />
        </Box>
      )}

      {redirectOtp && (
        <CustomSuccessNotification
          message={
            "Akun Grochat Anda telah berhasil diaktivasi. Silahkan login kembali untuk mulai menggunakan Grochat"
          }
          onClose={() => window.location.replace(successURL)}
          open={otpSuccess}
        />
      )}

      {!notValidate ? (
        <>
          {loading ? (
            <PuffLoader
              css={css}
              color={"black"}
              loading={loading}
              size={150}
            />
          ) : (
            <div>Akses Tidak Diijinkan</div>
          )}
        </>
      ) : (
        <>
          <CustomNotification
            open={loginErrorModal}
            onClose={() => setLoginErrorModal(false)}
            message={errorLogin ? errorLogin : ""}
            title={errorTitle}
          />
          <Box
            width="100%"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            flexDirection="column"
            style={{ gap: "20px" }}
          >
            <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
              <div className={styles.usernameContainer}>
                {errorMessage.length > 0 && (
                  <i className={styles.errorMessage}>{`*${errorMessage}`}</i>
                )}
                <InputText
                  type={"text"}
                  name={"userName"}
                  label={"Nama Pengguna"}
                  placeholder={"Ketik user ID / nomor ponsel anda"}
                  onChange={handleChange}
                  value={loginData.userName}
                  width={"100%"}
                />
              </div>
              <div className={styles.passwordContainer}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-password" shrink>
                    Kata Sandi
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={typePassword ? "text" : "password"}
                    onChange={handleChange}
                    name={"password"}
                    value={loginData.password}
                    placeholder={"Ketik kata sandi"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {typePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>

              <div className={styles.loginMiddleContainer}>
                <Box sx={{ display: "flex", alignItems: "center" }}></Box>
                <div
                  className={styles.forgotPassword}
                  onClick={() => setOpenForgot(true)}
                >
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    color="#CF0000"
                    sx={{ textDecoration: "underlined" }}
                  >
                    LUPA KATA SANDI
                  </Typography>
                </div>
              </div>
              <Box sx={{ height: "60px" }}></Box>

              <Button
                value={"MASUK"}
                width={"80%"}
                height={"45px"}
                background={"#D81922"}
                color={"white"}
              />
            </form>
            {isRegister === "true" && (
              <Button
                value={"DAFTAR"}
                height={"45px"}
                fontWeight={600}
                width={matches ? "72%" : "65%"}
                background={"white"}
                color={"#D81922"}
                style={{ marginTop: "-20px" }}
                onClick={() => setOpenRegister(true)}
              />
            )}
            <Typography
              fontSize={10}
              color={"#9E9E9E"}
              style={{
                marginBottom: matches ? "20px" : "0px",
              }}
            >
              VERSION 1.0.0
            </Typography>
          </Box>
        </>
      )}
    </div>
  );
}
